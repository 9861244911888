import { useWeb3React } from '@telekomconsalting/core'
import { model } from '@telekomconsalting/dex-guru-model'
import { ReactComponent as FavoritesIcon } from 'app-images/icons/emoji/favorites.svg'
import { ReactComponent as Mushroom } from 'app-images/icons/emoji/mushroom.svg'
import { ReactComponent as LogoDexGuru } from 'app-images/logo.svg'
import classNames from 'classnames'
import React, { FC, Suspense, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { setOmniboxSearchOpen } from '../../actions/uiActions'
import { AmplitudeEvent } from '../../constants/amplitudeEvents'
import { useAuth } from '../../containers/AuthWrapper'
import { Filter, Transaction } from '../../model'
import { State } from '../../reducers'
import { buildTokenProfileRoute, buildTraderProfileRoute } from '../../routes'
import amplitudeService from '../../services/amplitudeService'
import { getMarketDisplayName } from '../../utils'
import { useConnectors } from '../ConnectorsContext'
import LayoutSelector from '../LayoutSelector'
import { DropdownSearchTabs } from '../Omnibox/Dropdown/helpers'
import Omnibox from '../Omnibox/Omnibox'
import SwitchCurrency from '../SwitchCurrency'

const AnimatedFavorites = React.lazy(() => import('../AnimatedFavorites'))

const WalletButton = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ '@telekomconsalting/react-dexguru-wallet/lib/Wallet/WalletButton'
    )
)
const WalletAccountButton = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ '@telekomconsalting/react-dexguru-wallet/lib/Wallet/WalletAccountButton'
    )
)

const HeaderDesktop: FC = () => {
  const { isAuth, openProviderMenu } = useAuth()
  const history = useHistory()

  const location = useLocation()

  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  const networksConfig = useSelector((state: State) => state.networksConfig.data)
  const [filter, setFilter] = useState<Filter>(Filter.up)
  const activeTradeType = useSelector((store: State) => store.activeTradeType)
  const txn: Transaction = useSelector((state: State) => state.txn[activeTradeType])
  const { isHeaderFavoriteAnimationActive, isOmniboxSearchOpen } = useSelector(
    (store: State) => store.ui
  )

  const reduxDispatch = useDispatch()
  const { account } = useWeb3React()
  const { walletConnectors } = useConnectors()

  const onClickWalletTraderProfile = (e: React.MouseEvent): void => {
    e.preventDefault()

    if (account) {
      amplitudeService.sendEvent(AmplitudeEvent.CLICKED_TRADER_PROFILE_IN_WALLET_MODAL)
      history.push(buildTraderProfileRoute(account.toLowerCase()))
    }
  }

  const handleOpenDropsearch = (
    tab: Filter,
    event?: AmplitudeEvent,
    isProtected?: boolean
  ): void => {
    if (!tab) {
      return
    }

    reduxDispatch(setOmniboxSearchOpen(true, true))
    setFilter(tab)

    if (event) {
      amplitudeService.sendEvent(event, { wallet_address: account })
    }

    if (!isAuth && isProtected) {
      openProviderMenu()
    }
  }

  const currentTokenSymbol = getMarketDisplayName(currentToken)

  const isOpenTokenProfile = location.pathname.match(/\/token\/(.*)\/profile/)

  // eslint-disable-next-line no-console
  console.log('*** render ui', networksConfig, isAuth, account)

  return (
    <React.Fragment>
      <div className="header test-scss-m">
        <div className="header__logo">
          <a href="/" className="logo">
            <LogoDexGuru className="logo__text" />
          </a>
        </div>
        <div className="header__omnibox">
          <Omnibox isActiveSearch={isOmniboxSearchOpen} filter={filter} setFilter={setFilter} />
        </div>
        <div className="header__actions">
          {Object.entries(DropdownSearchTabs).map((entry, idx) => {
            const [name, button] = entry
            return (
              <button
                aria-label={button.title}
                key={`${idx}-${button.title}`}
                className={`header__button header__button--${name}`}
                data-for="app-tooltip"
                data-tip={button.title}
                onClick={(): void => {
                  handleOpenDropsearch(
                    name as Filter,
                    button?.headerAmplitudeEvent,
                    button.isProtected
                  )
                }}>
                <span className="icon">
                  {name === Filter.favorite ? (
                    <>
                      {isHeaderFavoriteAnimationActive && <AnimatedFavorites />}
                      <FavoritesIcon className="favorites-target" />
                    </>
                  ) : (
                    button.icon
                  )}
                </span>
              </button>
            )
          })}
        </div>
        <div className="header__actions">
          {currentToken &&
            (currentToken.marketType === 'token' ||
              process.env.REACT_APP_LP_TOKENS_PROFILE === 'true') && (
              <Link
                onClick={(): void => {
                  if (!currentToken) {
                    return
                  }

                  const tokenName = getMarketDisplayName(currentToken)
                  amplitudeService.dyorPopupOpened(currentToken.id, tokenName, currentToken.network)
                }}
                to={buildTokenProfileRoute(currentToken.id)}
                data-for="app-tooltip"
                data-tip={`View ${currentTokenSymbol} Token Profile for more details and data`}
                className={classNames('header__button header__button--overview', {
                  active: isOpenTokenProfile,
                })}>
                <span className="icon">
                  <Mushroom />
                </span>
                <span className="caption">Token Profile</span>
              </Link>
            )}
        </div>
        <div className="header__personal">
          <div className="header__layout">
            <LayoutSelector isMarketTypeToken={currentToken?.marketType === 'token'} />
          </div>
          {txn && !txn.isLoading && (
            <div className="header__currency">
              <SwitchCurrency />
            </div>
          )}
          <div className="header-personal__wallet">
            {isAuth && (
              <Suspense fallback={<div />}>
                <WalletAccountButton
                  walletConnectors={walletConnectors}
                  onDialogToggle={(value: boolean): void => {
                    amplitudeService.sendEvent(AmplitudeEvent.WALLET_CONNECTED_BUTTON_CLICKED, {
                      open: value,
                    })
                  }}
                  networksConfig={networksConfig}
                  hashTxn={txn.hashTxn}
                  isLoading={txn && (txn.isLoading || Boolean(txn.approvalInProgress))}
                  traderProfileURL={
                    account ? buildTraderProfileRoute(account.toLowerCase()) : undefined
                  }
                  tooltipTXPending="View on block explorer"
                  onClickTraderProfile={onClickWalletTraderProfile}
                  onClickCopyAddress={(account?: model.Address | null): void => {
                    amplitudeService.sendEvent(AmplitudeEvent.COPIED_ADDRESS_IN_WALLET_MODAL, {
                      wallet_address: account,
                    })
                  }}
                  onClickZerion={(account?: model.Address | null): void => {
                    amplitudeService.sendEvent(AmplitudeEvent.OPENED_ZERION_IN_WALLET_MODAL, {
                      wallet_address: account,
                    })
                  }}
                  onClickNetwork={(
                    network?: model.NetworkConfigV3,
                    account?: model.Address | null
                  ): void => {
                    amplitudeService.sendEvent(
                      AmplitudeEvent.OPENED_BLOCKCHAIN_EXPLORER_IN_WALLET_NETWORK,
                      { wallet_address: account, network: network?.name?.toUpperCase() }
                    )
                  }}
                />
              </Suspense>
            )}
            {!isAuth && (
              <Suspense fallback={<div />}>
                <WalletButton
                  tooltipWalletConnect="Connect Wallet"
                  openProviderMenu={(): void => {
                    amplitudeService.sendEvent(AmplitudeEvent.WALLET_BUTTON_CLICKED)
                    openProviderMenu()
                  }}
                />
              </Suspense>
            )}
          </div>
        </div>
      </div>
      <div className={`overlay-search ${isOmniboxSearchOpen ? 'show' : 'hide'}`} />
    </React.Fragment>
  )
}

export default HeaderDesktop
