import React from 'react'

import IconTokenComponent from './IconTokenComponent'

const IconTokenWrapper = ({
  logoURI,
  symbols,
  className,
  width,
  height,
}: {
  logoURI: string[]
  symbols: string[]
  className?: string
  width?: string
  height?: string
}): React.ReactElement => {
  return (
    <>
      {symbols.map((symbol, i) => (
        <IconTokenComponent
          key={`${symbol}-${i}`}
          iconToken={logoURI[i] || undefined}
          symbol={symbol}
          className={className}
          width={width}
          height={height}
        />
      ))}
    </>
  )
}

export default IconTokenWrapper
