import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { HeadingSize } from '../constants'
import { TooltipAnchor } from './TooltipAnchor'

type HeadingElements = Record<HeadingSize, string>

const elements: HeadingElements = {
  xl: 'h1',
  lg: 'h2',
  md: 'h3',
  sm: 'h4',
  xs: 'h5',
  xxs: 'h6',
}

export const Heading: FC<{
  size?: HeadingSize
  title?: ReactNode
  className?: string
  tooltip?: string
}> = ({ size = 'md', title, className, children, tooltip }) => {
  return React.createElement(
    elements[size],
    {
      className: classNames('heading', { [`heading--${size}`]: size }, className),
    },
    <>
      <span className="caption">{children || title}</span>
      {tooltip && <TooltipAnchor tooltip={tooltip} />}
    </>
  )
}
