import { ReactComponent as Unknown } from 'app-images/icons/tokens/unknown.svg'
import React, { useEffect, useState } from 'react'

const IconTokenComponent = ({
  iconToken,
  symbol,
  className,
  width,
  height,
}: {
  iconToken?: string
  symbol?: string
  className?: string
  width?: string
  height?: string
}): React.ReactElement => {
  const [hasError, setHasError] = useState<boolean>(false)

  useEffect(() => {
    setHasError(false)
  }, [iconToken])

  const handleImageError = (): void => {
    setHasError(true)
  }

  if (!iconToken || hasError) {
    return <Unknown />
  }

  return (
    <img
      className={className}
      onError={handleImageError}
      src={`${iconToken}`}
      alt={symbol}
      title={symbol}
      width={width}
      height={height}
    />
  )
}

export default IconTokenComponent
